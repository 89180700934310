import axios from 'axios';
import { PAGINATION } from 'modules/CustomerDetails/constants';

const isLocal = process.env.NODE_ENV === 'development';
const isLocalApiProd = true;

const isBrowser = typeof window !== 'undefined';

export const API_URL = Object.freeze({
  // Customer APIs
  GET_CUSTOMER_BY_ID: id => `/user/get/${id}`,
  TOGGLE_CUSTOMER: id => `/user/toggle/${id}`,
  GET_ALL_TAGS: '/tags/get',
  GET_ALL_CUSTOMERS: (offset = 0, limit = 10) =>
    `/user/get-all?offset=${offset}&limit=${limit}`,
  GET_FILTERED_CUSTOMERS: '/user/get-all/query',
  ADD_TAGS_TO_CUSTOMERS: '/user/tag/bulk',
  REMOVE_TAGS_FROM_CUSTOMERS: '/user/tag/bulk/remove',
  GET_CUSTOMER_ORDERS: ({
    id,
    offset = 0,
    limit = PAGINATION,
    sortBy = 'createdAt',
    sortDirection = 'desc',
  }) =>
    `/orders/user/${id}?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
  GET_PRICE_LISTS: ({
    offset = 0,
    limit = PAGINATION,
    sortBy = 'updatedAt',
    sortDirection = 'desc',
  }) =>
    `/price-list?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortOrder=${sortDirection}`,
  GET_PRICE_LIST_SKU: `/items/search`,

  // Tags APIs

  ADD_TAG_TO_CUSTOMER: customerId => `/user/${customerId}/tag`,
  REMOVE_TAG_FROM_CUSTOMER: customerId => `/user/${customerId}/tag`,

  //Create Customer APIs
  ADD_CUSTOMER: 'customer/create',

  // User identity related APIs
  AUTH_REFRESH_TOKEN: () => 'user/refresh-token',

  GET_SEGMENTS: 'segments',
  SEGMENT_FROM_TRAITS: 'segment-from-traits',
  TRAIT_MATCHING_CUSTOMERS: 'segment-from-traits/search',
});

export const xSiteContext = localEnv => {
  return {
    'x-site-context': JSON.stringify({
      date: new Date().toISOString(),
      account:
        localStorage.getItem('isLoggedInWithIdV2') === 'true'
          ? sessionStorage.getItem('accountId')
          : sessionStorage.getItem('account'), // account is used in iDv1 and accountID is used in iDv2
      stage: localEnv ? 'dev02' : process.env.SLS_STAGE,
      channel: 12,
    }),
  };
};

const createAxiosInstanceFromURL = url => {
  return axios.create({
    url,
    responseType: 'json',
    headers: {
      Authorization: isBrowser ? sessionStorage.getItem('accessToken') : '',
      ...xSiteContext(isLocal),
    },
  });
};

const axiosInstance = (port, domain) => {
  const localApiProd = isLocalApiProd === true ? `https://dev02.copilot.fabric.inc/api-${domain}` : `http://localhost:${port}/api-${domain}/${domain}`;
  const baseURL =
    isLocal === false
      ? `/api-${domain}/${domain}`
      : localApiProd
  return createAxiosInstanceFromURL(baseURL);
};

const segmentationAxiosInstance = () => {
  const url = `https://${
    isLocal ? 'dev02' : process.env.SLS_STAGE
  }-apigw.customersandbox.fabric.inc`;
  const axiosInstance = axios.create({ responseType: 'json' });
  axiosInstance.defaults.headers['x-api-key'] =
    'ec8b3754-170b-11ec-9621-0242ac130002';
  axiosInstance.defaults.headers['tenant-key'] =
    localStorage.getItem('isLoggedInWithIdV2') === 'true'
      ? sessionStorage.getItem('accountId')
      : sessionStorage.getItem('account');
  axiosInstance.defaults.baseURL = url;
  return axiosInstance;
};

const domainsAxiosInstances = {
  identity: axiosInstance('5001', 'identity'),
  oms: axiosInstance('5332', 'oms'),
  offers: axiosInstance('3000', 'offers'),
  customer: createAxiosInstanceFromURL(
    `https://${
      isLocal ? 'dev02' : process.env.SLS_STAGE
    }.copilot.fabric.inc/data-customer`
  ),
  segmentation: segmentationAxiosInstance(),
};

const { identity, oms, offers } = domainsAxiosInstances;

const getBaseUrl = ({ domain, port }) => {
  const localApiProd = isLocalApiProd === true ? `https://dev02.copilot.fabric.inc/api-${domain}` : `http://localhost:${port}/api-${domain}/${domain}`;
  const baseURL =
    isLocal === false
      ? `/api-${domain}/${domain}`
      : localApiProd;
  return baseURL;
};

const interceptorsRequest = (domainInstance, port, domain) => {
  domainInstance.interceptors.request.use(function (request) {
    request.baseURL = isLocal
      ? getBaseUrl({ domain, port })
      : `${window.location.origin}/api-${domain}`;
    request.headers['x-site-context'] = JSON.stringify({
      date: new Date().toISOString(),
      channel: 12,
      account:
        localStorage.getItem('isLoggedInWithIdV2') === 'true'
          ? sessionStorage.getItem('accountId')
          : sessionStorage.getItem('account'),
      stage: isLocal ? 'stg02' : process.env.SLS_STAGE,
    });
    return request;
  });
};

const domainResponse = response => {
  return response;
};

const ErrorResponse = async error => {
  if (error && error.response && error.response.status === 403) {
    try {
      const { data } = await identity.post('auth/local/refresh', {
        refreshToken: localStorage.getItem('refreshToken'),
      });

      if (data.accessToken) {
        const { accessToken, refreshToken } = data;
        sessionStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: accessToken,
          },
        };

        if (request.baseURL.includes('identity')) {
          return identity.request(request);
        } else if (request.baseURL.includes('oms')) {
          return oms.request(request);
        } else if (request.baseURL.includes('offers')) {
          return offers.request(request);
        }
        return;
      } else {
        return Promise.reject(error);
      }
    } catch (err) {
      console.log('Caught Error', err);

      const authUrl = isLocal
        ? 'http://localhost:4101/auth/login'
        : `${window.location.origin}/auth/login`;

      window.location.href = authUrl;
    }
  }

  //Handle 500 Network error

  return handleServerApiErrors(error);
};

const handleServerApiErrors = (error) => {
  if (error && error.response && error.response.status === 500) {
    return error.response;
  }
}

const interceptorsResponse = (domainInstance, domainResponse) => {
  domainInstance.interceptors.response.use(domainResponse, ErrorResponse);
};

interceptorsRequest(oms, '5332', 'oms');
interceptorsRequest(offers, '3000', 'offers');
interceptorsRequest(identity, '5001', 'identity');

interceptorsResponse(oms, domainResponse);
interceptorsResponse(offers, domainResponse);
interceptorsResponse(identity, domainResponse);

export default domainsAxiosInstances;
