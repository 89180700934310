"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.V3_API_FEATURE_FLAG = exports.COMMON_BREADCRUMB = exports.TAG_FIELD_VALIDATION = exports.OTHER_ERROR = exports.PERMISSION_ERROR = exports.CONTRACT_PERMISSIONS = exports.CUSTOMER_PERMISSIONS = exports.ORG_PERMISSIONS = exports.VALIDATED_FIELDS = exports.VALIDATE_FIELDS_ADD_CUSTOMER = exports.ADD_CUSTOMER = exports.COUNTRIES = exports.USER_STATUSES = exports.CUSTOMER_INACTIVE_STATUS = exports.CUSTOMER_ACTIVE_STATUS = exports.DEFAULT_STATUSES = exports.CUSTOMERS_PAGE_TABLE_HEADERS_CDP = exports.ORDER_STATUS_ARRAY = exports.CUSTOMERS_PAGE_TABLE_HEADERS = exports.GLOBAL_HEADER_LINKS = void 0;
var countries_list_1 = require("countries-list");
var GLOBAL_HEADER_LINKS = [
    {
        href: '/overview',
        icon: 'overview',
        text: 'Overview',
    },
    {
        href: '/pim',
        icon: 'pim',
        text: 'PIM',
    },
    {
        href: '/xpm',
        icon: 'xpm',
        text: 'XPM',
    },
    {
        href: '/offers',
        icon: 'offers',
        text: 'OFFERS',
    },
    {
        href: '/oms',
        icon: 'oms',
        text: 'OMS',
    },
    {
        active: true,
        href: '/customers',
        icon: 'POS',
        text: 'Customers',
    },
];
exports.GLOBAL_HEADER_LINKS = GLOBAL_HEADER_LINKS;
var CUSTOMERS_PAGE_TABLE_HEADERS = function (_a) {
    var name = _a.name, location = _a.location, lastActivity = _a.lastActivity, tags = _a.tags, menu = _a.menu;
    return [
        {
            className: 'customer-name-column',
            accessor: 'customerName',
            isSortable: true,
            render: name,
            title: 'Customer name',
        },
        {
            className: 'location-column',
            accessor: 'location',
            isSortable: true,
            render: location,
            title: 'Location',
        },
        {
            className: 'last-activity-column',
            accessor: 'lastActivity',
            isSortable: false,
            render: lastActivity,
            title: 'Last activity',
        },
        {
            className: 'tags-column',
            accessor: 'tags',
            isSortable: true,
            render: tags,
            title: 'Tags',
        },
        {
            className: 'menu-column',
            accessor: 'menu',
            isSortable: false,
            render: menu,
            title: '',
        },
    ];
};
exports.CUSTOMERS_PAGE_TABLE_HEADERS = CUSTOMERS_PAGE_TABLE_HEADERS;
var CUSTOMERS_PAGE_TABLE_HEADERS_CDP = function (_a) {
    var name = _a.name, defaultShippingAddress = _a.defaultShippingAddress, status = _a.status, tags = _a.tags;
    return [
        {
            className: 'customer-name-column',
            accessor: 'name',
            isSortable: true,
            render: name,
            title: 'Customer name',
        },
        {
            className: 'default-shipping-address-column',
            accessor: 'defaultShippingAddress',
            isSortable: false,
            render: defaultShippingAddress,
            title: 'Default Shipping Address',
        },
        {
            className: 'last-activity-column',
            accessor: 'isActive',
            isSortable: true,
            render: status,
            title: 'Status',
        },
        {
            className: 'tags-column',
            accessor: 'tags',
            isSortable: false,
            render: tags,
            title: 'Tags',
        },
    ];
};
exports.CUSTOMERS_PAGE_TABLE_HEADERS_CDP = CUSTOMERS_PAGE_TABLE_HEADERS_CDP;
var DEFAULT_STATUSES = [
    {
        id: 0,
        name: 'Inactive',
        value: 'inactive',
    },
    {
        id: 1,
        name: 'Active',
        value: 'active',
    },
];
exports.DEFAULT_STATUSES = DEFAULT_STATUSES;
var CUSTOMER_ACTIVE_STATUS = 'Active';
exports.CUSTOMER_ACTIVE_STATUS = CUSTOMER_ACTIVE_STATUS;
var CUSTOMER_INACTIVE_STATUS = 'Inactive';
exports.CUSTOMER_INACTIVE_STATUS = CUSTOMER_INACTIVE_STATUS;
var ORDER_STATUS = {
    ORDER_CREATED: {
        name: 'Created',
        theme: 'statusOrange',
        bullet: 'orange-bullet',
        color: '#FD9F1C',
    },
    ORDER_CONFIRMED: {
        name: 'Confirmed',
        theme: 'statusOrange',
        bullet: 'orange-bullet',
        color: '#E84869',
    },
    ORDER_CANCELLED: {
        name: 'Cancelled',
        theme: 'statusGrey',
        bullet: 'grey-bullet',
        color: '#737F8F',
    },
    ORDER_PARTIALLY_SHIPPED: {
        name: 'Partially Shipped',
        theme: 'statusGreen',
        bullet: 'green-bullet',
        color: '#3D82FF',
    },
    ORDER_SHIPPED: {
        name: 'Shipped',
        theme: 'statusGreen',
        bullet: 'green-bullet',
        color: '#3D82FF',
    },
    ORDER_PARTIALLY_DELIVERED: {
        name: 'Partially Delivered',
        theme: 'statusBlue',
        bullet: 'blue-bullet',
        color: '#008A1E',
    },
    ORDER_DELIVERED: {
        name: 'Delivered',
        theme: 'statusBlue',
        bullet: 'blue-bullet',
        color: '#008A1E',
    },
    ORDER_RETURNED: {
        name: 'Returned',
        theme: 'statusGrey',
        bullet: 'grey-bullet',
        color: '#384454',
    },
    ORDER_PARTIALLY_RETURNED: {
        name: 'Partially Returned',
        theme: 'statusGrey',
        bullet: 'grey-bullet',
        color: '#384454',
    },
    ORDER_PAYMENT_AUTHORIZED: {
        name: 'Payment Authorized',
        theme: 'statusOrange',
        bullet: 'orange-bullet',
        color: '#8458F6',
    },
    ORDER_PAYMENT_INVALID: {
        name: 'Payment Invalid',
        theme: 'statusGrey',
        bullet: 'grey-bullet',
        color: '#D50000',
    },
};
var ORDER_STATUS_ARRAY = [];
exports.ORDER_STATUS_ARRAY = ORDER_STATUS_ARRAY;
Object.keys(ORDER_STATUS).forEach(function (key) {
    ORDER_STATUS_ARRAY.push({ name: ORDER_STATUS[key].name, value: key });
});
var COUNTRIES = Object.keys(countries_list_1.countries)
    .sort(function (a, b) { return countries_list_1.countries[a].name.localeCompare(countries_list_1.countries[b].name); })
    .map(function (country) {
    var id = country;
    if (country === 'GB') {
        id = 'UK';
    }
    if (country === 'US') {
        id = 'USA';
    }
    return {
        id: id,
        name: countries_list_1.countries[country].name,
        label: countries_list_1.countries[country].name,
    };
});
exports.COUNTRIES = COUNTRIES;
var ADD_CUSTOMER = {
    LABELS: {
        BASIC_INFORMATION: 'Basic information',
        SHOWING_ATTRIBUTES: 'Showing 2 attributes',
        ADDITIONAL_INFORMATION: 'Additional information',
        SHOWING: 'Showing',
        ATTRIBUTES: 'Attributes',
    },
};
exports.ADD_CUSTOMER = ADD_CUSTOMER;
var VALIDATED_FIELDS = {
    addressLine1: {
        error: false,
        message: 'Address cannot be empty',
        type: 'address',
    },
    addressLine2: {
        error: false,
        message: 'Address cannot be empty',
        type: 'address',
    },
    addressLine3: {
        error: false,
        message: 'Address cannot be empty',
        type: 'address',
    },
    addressLine4: {
        error: false,
        message: 'Address cannot be empty',
        type: 'address',
    },
    zipCode: {
        error: false,
        message: 'Zip code cannot be empty',
        type: 'numeric',
    },
    city: {
        error: false,
        message: 'City cannot be empty',
        type: 'city',
    },
};
exports.VALIDATED_FIELDS = VALIDATED_FIELDS;
var USER_STATUSES;
(function (USER_STATUSES) {
    USER_STATUSES["ACTIVE"] = "ACTIVE";
    USER_STATUSES["INACTIVE"] = "INACTIVE";
})(USER_STATUSES || (USER_STATUSES = {}));
exports.USER_STATUSES = USER_STATUSES;
var VALIDATE_FIELDS_ADD_CUSTOMER = {
    name: {
        error: false,
        message: 'Invalid name',
        type: 'name',
    },
    email: {
        error: false,
        message: 'Invalid email',
        type: 'email',
    },
    dateOfBirth: {
        error: false,
        message: 'Invalid date, please use a valid date format',
        type: 'date',
    },
    phoneNumber: {
        error: false,
        message: 'Invalid phone',
        type: 'numeric',
    },
    streetAddress: {
        error: false,
        message: 'Invalid address',
        type: 'address',
    },
    city: {
        error: false,
        message: 'Invalid city',
        type: 'city',
    },
    state: {
        error: false,
        message: 'Invalid state',
        type: 'state',
    },
    country: {
        error: false,
        message: 'Invalid country',
        type: 'state',
    },
    zipCode: {
        error: false,
        message: 'Invalid zipCode',
        type: 'numeric',
    },
};
exports.VALIDATE_FIELDS_ADD_CUSTOMER = VALIDATE_FIELDS_ADD_CUSTOMER;
exports.ORG_PERMISSIONS = {
    ORG_READ: 'customer:v1:organization:read',
    ORG_CREATE: 'customer:v1:organization:create',
    ORG_UPDATE: 'customer:v1:organization:update',
    ORG_DELETE: 'customer:v1:organization:delete',
    ORG_ADDRESS_READ: 'customer:v1:organization.address:read',
    ORG_ADDRESS_CREATE: 'customer:v1:organization.address:create',
    ORG_ADDRESS_UPDATE: 'customer:v1:organization.address:update',
    ORG_ADDRESS_DELETE: 'customer:v1:organization.address:delete',
    ORG_USER_READ: 'customer:v1:organization.user:read',
    ORG_USER_CREATE: 'customer:v1:organization.user:create',
    ORG_USER_UPDATE: 'customer:v1:organization.user:update',
    ORG_USER_DELETE: 'customer:v1:organization.user:delete',
    ORG_CONTRACT_READ: 'customer:v1:organization.contract:read',
    ORG_CONTRACT_CREATE: 'customer:v1:organization.contract:create',
    ORG_CONTRACT_UPDATE: 'customer:v1:organization.contract:update',
    ORG_CONTRACT_DELETE: 'customer:v1:organization.contract:delete',
    ORG_GROUP_READ: 'customer:v1:organization.group:read',
    ORG_GROUP_CREATE: 'customer:v1:organization.group:create',
    ORG_GROUP_UPDATE: 'customer:v1:organization.group:update',
    ORG_GROUP_DELETE: 'customer:v1:organization.group:delete',
    ORG_GROUP_ADDRESS_READ: 'customer:v1:organization.group.address:read',
    ORG_GROUP_USER_READ: 'customer:v1:organization.group.user:read',
};
exports.CUSTOMER_PERMISSIONS = {
    CUST_CREATE: 'customer:v1:individual:create',
    CUST_UPDATE: 'customer:v1:individual:update',
    CUST_READ: 'customer:v1:individual:read',
    CUST_ADDRESS_READ: 'customer:v1:individual.address:read',
    CUST_ADDRESS_UPDATE: 'customer:v1:individual.address:update',
    CUST_ADDRESS_DELETE: 'customer:v1:individual.address:delete',
    CUST_ADDRESS_CREATE: 'customer:v1:individual.address:create',
    CUST_TRAIT_CREATE: 'customer:v1:trait:create',
    CUST_TRAIT_READ: 'customer:v1:trait:read',
    CUST_TRAIT_DELETE: 'customer:v1:trait:delete',
};
exports.CONTRACT_PERMISSIONS = {
    CONT_READ: 'customer:v1:contract:read',
    CONT_CREATE: 'customer:v1:contract:create',
    CONT_UPDATE: 'customer:v1:contract:update',
};
exports.PERMISSION_ERROR = {
    CUSTOMERS_ERROR: "You don't have permission to access customers",
    CUSTOMERS_ADD_ERROR: "You don't have permission to add customers",
    CUSTOMERS_EDIT_ERROR: "You don't have permission to edit customers",
    CUSTOMERS_READ_ADDRESS: "You don't have permission to read customer address",
    ORGANIZATION_ERROR: "You don't have permission to access organization",
    CONTRACTS_ERROR: "You don't have permission to access contracts",
    SECONDARY_TEXT: 'Please request access from an admin',
    MAIN_ERROR: "You don't have permission to view this",
    MAIN_ERROR_SECONDARY: 'please request your admin for access',
};
exports.OTHER_ERROR = {
    CUSTOMERS_UPDATE_ERROR: 'Failed to update customer details, kindly try again',
    CUSTOMERS_UPDATE_SUCCESS: 'Customer details updated',
    CUSTOMERS_CREATE_ERROR: 'Unable to create a new customer',
};
exports.TAG_FIELD_VALIDATION = {
    ERROR_HYPHEN: 'hyphens are not allowed',
};
exports.COMMON_BREADCRUMB = [
    {
        label: 'Home',
        url: '/',
    },
    {
        label: 'Customers',
        url: '/customer/customers',
        // disabled: true,
    },
    {
        label: 'Manage Customers',
        url: '/customer/customers',
    },
];
exports.V3_API_FEATURE_FLAG = 'copilot-customer-v3-api';
